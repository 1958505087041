<template>
    <section id="features" class="section features-area ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-6">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2>
                                اكتشف الميزات المتميزة
                            </h2>
                            <p class="d-none d-sm-block mt-4">يتميز تطبيق اونلاين بسهولة عالية و مميزات تساعد الوكلاء للحصول على كل احتياجاته</p>
                            <p class="d-block d-sm-none mt-4">كما يتميز التطبيق بتوفير مجموعة كبيرة من المنصات و البطاقات العالمية و المحلية</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div
                        v-for="(item, i) in items"
                        :key="i"
                        class="col-12 col-md-6 col-lg-4"
                    >
                        <!-- Icon Box -->
                        <div class="icon-box text-center p-4 wow fadeInUp" data-wow-duration="2s">
                            <!-- Featured Icon -->
                            <div class="featured-icon mb-3">
                                <span :class="item.icon"></span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text">
                                <h3 class="mb-2">{{ item.title }}</h3>

                                <p v-if="item.desc">
                                    {{ item.desc }}    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                icon: 'flaticon-tap',
                title: 'سرعة في الاداء',
                desc: ''
            },
            {
                icon: 'flaticon-fingerprint',
                title: 'امان عالي',
                desc: '',
            },
            {
                icon: 'flaticon-notification',
                title: 'مزايا و خدمات متجددة',
                desc: '',
            },
            {
                icon: 'flaticon-place',
                title: 'احصائيات و تقارير مفصلة',
                desc: '',
            },
            {
                icon: 'flaticon-settings',
                title: 'اعدادات مخصصة للوكلاء',
                desc: '',
            },
            {
                icon: 'flaticon-language',
                title: 'متعدد اللغات',
                desc: '',
            }
        ]
    })
}
</script>

<style>

</style>