import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/pages/home-page'
import Pricing from '@/components/InnerPages/PricingPage/pricing'
import Download from '@/components/InnerPages/DownloadPage/download'
import Newsletter from '@/components/InnerPages/NewsletterPage/newsletter'
import ThankYou from '@/components/InnerPages/ThankYou/thankyou'
import ComingSoon from '@/components/InnerPages/ComingSoon/coming-soon'
import Error from '@/components/InnerPages/Error/404'
import BlogTwoColumn from '@/components/Blogs/BlogTwoColumn/blog-two-column'
import BlogThreeColumn from '@/components/Blogs/BlogThreeColumn/blog-three-column'
import BlogLeftSidebar from '@/components/Blogs/BlogLeftSidebar/blog-left-sidebar'
import BlogRightSidebar from '@/components/Blogs/BlogRightSidebar/blog-right-sidebar'
import BlogDetailsLeftSidebar from '@/components/Blogs/BlogDetailsLeftSidebar/blog-details-left-sidebar'
import BlogDetailsRightSidebar from '@/components/Blogs/BlogDetailsRightSidebar/blog-details-right-sidebar'
import Login from '@/components/Accounts/Login/login'
import SignUp from '@/components/Accounts/SignUp/signup'
import Forgot from '@/components/Accounts/Forgot/forgot'
import Reviews from '@/components/InnerPages/ReviewPage/reviews'
import Contact from '@/components/InnerPages/ContactPage/contact'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/download',
      name: 'Download',
      component: Download
    },
    {
      path: '/newsletter',
      name: 'Newsletter',
      component: Newsletter
    },
    {
      path: '/thankyou',
      name: 'ThankYou',
      component: ThankYou
    },
    {
      path: '/coming-soon',
      name: 'ComingSoon',
      component: ComingSoon
    },
    {
      path: '/404',
      name: 'Error',
      component: Error
    },
    {
      path: '/blog-two-column',
      name: 'BlogTwoColumn',
      component: BlogTwoColumn
    },
    {
      path: '/blog-three-column',
      name: 'BlogThreeColumn',
      component: BlogThreeColumn
    },
    {
      path: '/blog-left-sidebar',
      name: 'BlogLeftSidebar',
      component: BlogLeftSidebar
    },
    {
      path: '/blog-right-sidebar',
      name: 'BlogRightSidebar',
      component: BlogRightSidebar
    },
    {
      path: '/blog-details-left-sidebar',
      name: 'BlogDetailsLeftSidebar',
      component: BlogDetailsLeftSidebar
    },
    {
      path: '/blog-details-right-sidebar',
      name: 'BlogDetailsRightSidebar',
      component: BlogDetailsRightSidebar
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: Forgot
    },
    {
      path: '/reviews',
      name: 'Reviews',
      component: Reviews
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    }
  ]
})