<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-7">
          <!-- Section Heading -->
          <div class="section-heading text-center">
              <h2 class="text-capitalize">
                الوكلاء
              </h2>
              <p class="mt-4">
                بعض الصور من الوكلاء المميزين في شبكة وكلائنا في مختلف انحاء العراق
              </p>
          </div>
      </div>
  </div>
    <masonry-wall :items="items" :ssr-columns="1" :column-width="300" :gap="16">
      <template #default="{ item, index }">
        <div
          class="item"
          @click="openGallery(index)"
        >
          <img
            :src="item.src"
            :alt="item.title"
          >

          <div class="meta">
            <h2>{{ item.title }}</h2>
            <span>{{ item.description }}</span>
          </div>
        </div>
      </template>
    </masonry-wall>

    <div dir="ltr">
      <CoolLightBox 
        :items="items" 
        :index="activeIndex"
        :full-screen="true"
        @close="activeIndex = null"
      />
    </div>
  </div>
</template>

<script>
import MasonryWall from '../../components/MasonryWall/MasonryWall.vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import items from './items'

export default {
  components: {
    MasonryWall,
    CoolLightBox,
  },
  data() {
    return {
      activeIndex: 0,
      items: items
    }
  },
  methods: {
    openGallery(index) {
      this.activeIndex = index
    }
  }
}
</script>

<style scoped>
.item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.item img {
  width: 100%;
  max-height: 100%;
  transition: all .5s ease-in-out;
}

.item:hover img {
  scale: 1.02;
}

.item .meta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 6px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.332);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.item:hover .meta {
  opacity: 100%;
}

.item .meta h2 {
  color: white;
}
</style>