<template>
  <section id="partners" class="section price-plan-area bg-gray overflow-hidden ptb_100">
    <div class="container">
      <div class="text-right">
        <h2>
          البطاقات المتوفرة
        </h2>
        <p class="mt-4">
          توفر منصة اونلاين العديد من الخيارات و الخدمات من مختلف الشركات العراقية و الاجنبية في مجال البطاقات الالكترونية و خدمات تعبئة الرصيد
        </p>
      </div>
      
      <div class="partner-grid">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="borderimg"
        >
          <img
            class="company"
            :src="item.img"
            :alt="item.title"
            :title="item.title"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: 'Asiacell',
        img: '/assets/img/companies/asiacell.png',
      },
      {
        title: 'Zain',
        img: '/assets/img/companies/zain2.png',
      },
      {
        title: 'Korek Telecom',
        img: '/assets/img/companies/korek2.png',
      },
      {
        title: "Careem",
        img: '/assets/img/companies/careem.png',
      },
      {
        title: "Baly",
        img: '/assets/img/companies/image 5.png',
      },
      {
        title: "Jawaker",
        img: '/assets/img/companies/image 7.png',
      },
      {
        title: "Mastercard",
        img: '/assets/img/companies/image 8.png',
      },
      {
        title: "Steam",
        img: '/assets/img/companies/image 9.png',
      },
      {
        title: "XBOX",
        img: '/assets/img/companies/image 10.png',
      },
      {
        title: "PlayStation",
        img: '/assets/img/companies/image 11.png',
      },
      {
        title: "Nintendo Switch",
        img: '/assets/img/companies/image 12.png',
      },
      {
        title: "Roblox",
        img: '/assets/img/companies/image 13.png',
      },
      {
        title: "Amazon Gift Cards",
        img: '/assets/img/companies/image 14.png',
      },
      {
        title: "PUBG",
        img: '/assets/img/companies/image 15.png',
      },
      {
        title: "IraqCell",
        img: '/assets/img/companies/image 16.png',
      },
      {
        title: "Al Kafeel Omnia",
        img: '/assets/img/companies/image 17.png',
      },
      {
        title: "Google Play",
        img: '/assets/img/companies/image 18.png',
      },
      {
        title: "iTunes",
        img: '/assets/img/companies/image 19.png',
      },
      {
        title: "Bigo Live",
        img: '/assets/img/companies/image 20.png',
      },
    ]
  })
}
</script>

<style scoped>
.partner-grid {
  display: grid;
  row-gap: 12px;
  column-gap: 34px;
  margin-top: 26px;
  grid-template-columns: repeat(6, 1fr);

}

@media screen and (max-width: 768px) {
  .partner-grid {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 14px;
  }
  
}

img.company {
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: contain;
}
.borderimg {
  background-color: white;
  padding: 20px;
  border: 1px solid #e5e5e5;
}
</style>