<template>
    <section id="screenshots" class="section screenshots-area ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-7">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">
                                صور التطبيق
                            </h2>
                            <p class="mt-4">
                                اكتشف المزيد من المزايا و الخدمات المتجددة عند استخدام منصة اونلاين للبطاقات الالكترونية
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row" dir="ltr">
                    <div class="col-12">
                        <!-- App Screenshot Slider Area -->
                        <div class="app-screenshots">
                            <!-- Single Screenshot Item -->
                            <div
                                v-for="(item, i) in items"
                                :key="i"
                                class="single-screenshot"
                            >
                                <img
                                    :src="item.img"
                                    :alt="item.title"
                                    :title="item.title"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import items from './items'

export default {
    data () {
        return {
            items
        }
    }
}
</script>

<style>

</style>