var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wall",staticClass:"masonry-wall",style:({ display: 'flex', gap: `${_vm.gap}px` })},_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:columnIndex,staticClass:"masonry-column",style:({
      display: 'flex',
      'flex-basis': 0,
      'flex-direction': 'column',
      'flex-grow': 1,
      height: ['-webkit-max-content', '-moz-max-content', 'max-content'],
      gap: `${_vm.gap}px`,
    }),attrs:{"data-index":columnIndex}},_vm._l((column),function(itemIndex){return _c('div',{key:itemIndex,staticClass:"masonry-item"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.items[itemIndex])+" ")]},{"item":_vm.items[itemIndex],"index":itemIndex})],2)}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }