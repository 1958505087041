export default [
  {
    title: 'اكس فون',
    description: 'X-Phone',
    src: '/assets/img/distro/1.png',
  },
  {
    title: 'كرار ابل',
    description: 'Karrar Apple',
    src: '/assets/img/distro/2.png',
  },
  {
    title: 'الصفا للموبايل',
    description: 'Al-Safa Mobile',
    src: '/assets/img/distro/3.png',
  },
  {
    title: 'الازل',
    description: 'Azal Mobile Services',
    src: '/assets/img/distro/4.png',
  },
  {
    title: 'X',
    description: 'X',
    src: '/assets/img/distro/5.png',
  },
  {
    title: 'سناب شات',
    description: 'SnapChat Mobile Services',
    src: '/assets/img/distro/6.png',
  },
  {
    title: 'الفرح للموبايل',
    description: 'Al-Farah Mobile',
    src: '/assets/img/distro/7.png',
  },
  {
    title: 'الفنار للموبايل',
    description: 'Al-Fanar Mobile Services',
    src: '/assets/img/distro/8.png',
  },
  {
    title: 'ستريت فون لخدمات الجوال',
    description: 'Street Phone Mobile Services',
    src: '/assets/img/distro/9.png',
  },
  {
    title: 'ستريت فون لخدمات الجوال',
    description: 'Street Phone Mobile Services',
    src: '/assets/img/distro/10.png',
  },
  {
    title: 'بي سنتر',
    description: 'B Center Mobile Services',
    src: '/assets/img/distro/11.png',
  },
];
