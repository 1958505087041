<template>
    <section class="section faq-area ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-7">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">
                                الأسئلة الأكثر شيوعا
                            </h2>
                            <p class="mt-4">
                                يمكنك مراجعة الاسئلة و الاجوبة المذكورة ادناه لمزيد من المعلومات عن خدماتنا
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <!-- FAQ Content -->
                        <div class="faq-content">
                            <!-- sApp Accordion -->
                            <div class="accordion" id="sApp-accordion">
                                <div class="row justify-content-center">
                                    <div class="col-12 col-md-10 col-lg-8">
                                        <!-- Single Accordion Item -->
                                        <div
                                            v-for="(item, i) in items"
                                            :key="i"
                                            class="card border-top-0 border-left-0 border-right-0 border-bottom text-right"
                                        >
                                            <!-- Card Header -->
                                            <div class="card-header bg-inherit border-0 p-0">
                                                <h2 class="mb-0">
                                                    <button
                                                        class="btn px-0 py-3"
                                                        type="button"
                                                        data-toggle="collapse"
                                                        :data-target="`#collapse-${i}`"
                                                    >
                                                        {{ item.title }}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div
                                                :id="`collapse-${i}`"
                                                class="collapse"
                                                data-parent="#sApp-accordion"
                                            >
                                                <!-- Card Body -->
                                                <div class="card-body px-0 py-3">
                                                    {{ item.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import items from './data'

export default {
    data: () => ({
        items
    })    
}
</script>

<style>

</style>