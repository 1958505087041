<template>
    <section
        id="register"
        class="section work-area bg-overlay overflow-hidden ptb_100"
    >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <!-- Work Content -->
                        <div class="work-content text-center">
                            <h2 class="text-white">
                                طريقة الاشتراك معنا
                            </h2>
                            <p class="text-white my-3 mt-sm-4 mb-sm-5">
                                اذا كنت شركة مزودة للخدمات و تود توسيع نطاق مبيعاتك، او اذا كنت وكيل و تود الاستفادة من خدماتنا الالكترونية في انشاء نقطة بيع واحدة تسهل عليك التعاملات مع عدة مزودين لا عليك سوى التواصل مع فريق المبيعات و سيتم مساعدتك بأسرع وقت    
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <!-- Single Work -->
                        <div class="single-work text-center p-3">
                            <!-- Work Icon -->
                            <div class="work-icon">
                                <img class="avatar-md" src="assets/img/work_thumb_1.png" alt="">
                            </div>
                            <h3 class="text-white py-3">
                                تهيئة العقد و تحديد نسب الارباح
                            </h3>
                            <!-- <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?</p> -->
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <!-- Single Work -->
                        <div class="single-work text-center p-3">
                            <!-- Work Icon -->
                            <div class="work-icon">
                                <img class="avatar-md" src="assets/img/work_thumb_2.png" alt="">
                            </div>
                            <h3 class="text-white py-3">
                                تنصيب الجهاز و تحميل التطبيق
                            </h3>
                            <!-- <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?</p> -->
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <!-- Single Work -->
                        <div class="single-work text-center p-3">
                            <!-- Work Icon -->
                            <div class="work-icon">
                                <img class="avatar-md" src="assets/img/work_thumb_3.png" alt="">
                            </div>
                            <h3 class="text-white py-3">
                                الدورة التدريبية لإستخدام الجهاز وتفعيل المبيعات
                            </h3>
                            <!-- <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    
}
</script>

<style>

</style>