export default [
  {
    title: "App",
    img: "/assets/img/app/1.png",
  },
  {
    title: "App",
    img: "/assets/img/app/2.png",
  },
  {
    title: "App",
    img: "/assets/img/app/3.png",
  },
  {
    title: "App",
    img: "/assets/img/app/4.png",
  },
  {
    title: "App",
    img: "/assets/img/app/5.png",
  },
  {
    title: "App",
    img: "/assets/img/app/6.png",
  },
  {
    title: "App",
    img: "/assets/img/app/7.png",
  },
  {
    title: "App",
    img: "/assets/img/app/8.png",
  },
]