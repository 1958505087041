<template>
    <section class="section download-area overlay-dark ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8">
                        <!-- Download Text -->
                        <div class="download-text text-center">
                            <h2 class="text-white">
                                تحميل التطبيق
                            </h2>
                            <p class="text-white my-3">
                                متوفر لأجهزة اندرويد فقط
                            </p>
                            <!-- Store Buttons -->
                            <div class="button-group store-buttons d-flex justify-content-center">
                                <a href="#">
                                    <img src="assets/img/google_play.png" alt="">
                                </a>
                                <!-- <a href="#">
                                    <img src="assets/img/app_store.png" alt="">
                                </a> -->
                            </div>
                            <!-- <span class="d-inline-block text-white fw-3 font-italic mt-3">* Available on iPhone, iPad and all Android devices</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    
}
</script>

<style>

</style>