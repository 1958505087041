<template>
    <footer id="footer" class="footer-area">
            <!-- Footer Top -->
            <div class="footer-top ptb_100">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Logo -->
                                <a class="navbar-brand" href="#">
                                    <img class="logo" src="assets/img/logo/logo.png" alt="">
                                </a>
                                <p class="mt-2 mb-3">
                                    جهاز و تطبيق اونلاين يتيح للوكلاء سهولة التعامل مع البطاقات الالكترونية وبطاقات تعبئة الرصيد من مختلف الشركات المحلية و الاجنبية بسهولة تامة وأنسب الاسعار
                                </p>
                                <!-- Social Icons -->
                                <div class="social-icons d-flex">
                                    <a class="facebook" href="https://www.facebook.com/profile.php?id=100070259142411" target="_blank">
                                        <i class="fab fa-facebook-f"></i>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <!-- <a class="twitter" href="#">
                                        <i class="fab fa-twitter"></i>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a class="google-plus" href="#">
                                        <i class="fab fa-google-plus-g"></i>
                                        <i class="fab fa-google-plus-g"></i>
                                    </a>
                                    <a class="vine" href="#">
                                        <i class="fab fa-vine"></i>
                                        <i class="fab fa-vine"></i>
                                    </a> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-5">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Footer Title -->
                                <h3 class="footer-title mb-2">
                                    الروابط
                                </h3>
                                <ul>
                                    <li class="py-2">
                                        <a href="#home">
                                            الرئسية
                                        </a>
                                    </li>
                                    <li class="py-2">
                                        <a href="#partners">
                                            البطاقات
                                        </a>
                                    </li>
                                    <li class="py-2">
                                        <a href="#register">
                                            طريقة الاشتراك
                                        </a>
                                    </li>
                                    <li class="py-2">
                                        <a href="#screenshots">
                                            صور التطبيق
                                        </a>
                                    </li>
                                    <li class="py-2">
                                        <a href="#footer">
                                            اتصل بنا
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div class="col-12 col-sm-6 col-lg-3">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                 <!-- Footer Title -->
                                 <h3 class="footer-title mb-2">للإتصال بنا</h3>
                                <!-- Contact Info -->
                                <ul>
                                    <li>
                                        <a href="https://maps.app.goo.gl/VJ59foFGCkC6QbJQ6" target="_blank">
                                        العراق - بغداد - حي الجامعة - مجاور مطعم جكن بوكس - مقابل مجوهرات الماس
                                        </a>
                                    </li>
                                    <li>
                                        البريد الالكتروني: 
                                        <a href="mailto:onlinecom.2002@gmail.com">onlinecom.2002@gmail.com</a>
                                    </li>
                                    <li>
                                        رقم الهاتف: 
                                       <a href="tel://+9647824009333">07824009333</a>
                                    </li>
                                    <li>
                                       رقم الهاتف: 
                                       <a href="tel://+9647724009333">07724009333</a>
                                    </li>
                                    
                                </ul>
                                <!-- Footer Title -->
                                <h3 class="footer-title mt-4">تثبيت التطبيق</h3>
                                <!-- Store Buttons -->
                                <div class="button-group store-buttons store-black d-flex flex-wrap">
                                    <a href="#">
                                        <img src="assets/img/google_play_black.png" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer Bottom -->
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- Copyright Area -->
                            <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                <!-- Copyright Left -->
                                <div class="copyright-left">&copy; Copyrights 2024 Online - All rights reserved.</div>
                                <!-- Copyright Right -->
                                <div class="copyright-right">Powered by eSITE Information Technology LLC</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>

<script>
export default {
    
}
</script>

<style>

</style>