<template>
  <section class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-7">
          <!-- Section Heading -->
          <div class="section-heading text-center">
              <h2 class="text-capitalize">
                الوكلاء
              </h2>
              <p class="mt-4">
                بعض الصور من الوكلاء المميزين في شبكة وكلائنا في مختلف انحاء العراق
              </p>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="menu-area text-right">
          <div class="search-wrapper">
            <svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.34286 0C4.22944 0 5.07971 0.352193 5.70661 0.9791C6.33352 1.60601 6.68571 2.45628 6.68571 3.34286C6.68571 4.17086 6.38229 4.932 5.88343 5.51829L6.02229 5.65714H6.42857L9 8.22857L8.22857 9L5.65714 6.42857V6.02229L5.51829 5.88343C4.932 6.38229 4.17086 6.68571 3.34286 6.68571C2.45628 6.68571 1.60601 6.33352 0.9791 5.70661C0.352193 5.07971 0 4.22944 0 3.34286C0 2.45628 0.352193 1.60601 0.9791 0.9791C1.60601 0.352193 2.45628 0 3.34286 0ZM3.34286 1.02857C2.05714 1.02857 1.02857 2.05714 1.02857 3.34286C1.02857 4.62857 2.05714 5.65714 3.34286 5.65714C4.62857 5.65714 5.65714 4.62857 5.65714 3.34286C5.65714 2.05714 4.62857 1.02857 3.34286 1.02857Z" fill="black" fill-opacity="0.2"/>
            </svg>

            <input
              type="text"
              class="search"
              v-model="keyword"
              placeholder="البحث عن طريق اسم الوكيل، رقم الهاتف او العنوان ..."
            />
          </div>
          <div
            v-if="isFetching"
            class="p-2"
          >
            جار تحميل البيانات...
          </div>
          <div
            v-else
            class="menu-wrapper"
          >
            <div
              v-for="(item, i) in filteredItems"
              :key="i"
              @click="setActive(item)"
              class="item"
              :class="{
                active: item.uuid === activeItem?.uuid
              }"
            >
              <div class="mt-1">
                <svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.50008 0.416687C13.2126 0.416687 16.9584 4.16252 16.9584 8.87502C16.9584 15.1584 8.50008 24.5834 8.50008 24.5834C8.50008 24.5834 0.041748 15.1584 0.041748 8.87502C0.041748 4.16252 3.78758 0.416687 8.50008 0.416687ZM8.50008 2.83335C5.11675 2.83335 2.45841 5.49169 2.45841 8.87502C2.45841 10.0834 2.45841 12.5 8.50008 20.5959C14.5417 12.5 14.5417 10.0834 14.5417 8.87502C14.5417 5.49169 11.8834 2.83335 8.50008 2.83335ZM8.50008 11.8959L11.4001 13.7084L10.6751 10.325L13.3334 8.02919L9.82925 7.78752L8.50008 4.52502L7.17091 7.66669L3.66675 8.02919L6.32508 10.325L5.47925 13.7084L8.50008 11.8959Z" fill="#F79E1E"/>
                </svg>
              </div>

              <div>
                <h4 style="font-size: 14px;">
                  {{ item.fullname }}
                </h4>

                <div class="mt-1" style="color: #7D7D7D; font-size: 12px; line-height: 1.4;">
                  <div>{{ item.address }}</div>
                  <div>{{ item.phone }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="map-area">
          <div id="map"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { Loader } from "@googlemaps/js-api-loader"

const apiKey = 'AIzaSyAJTZgTyq702hDXi5o-cqd5zpzgwSyXvZg';
const loader = new Loader({
  apiKey: apiKey,
  version: "weekly",
});

const initZoom = 12;

export default {
  data: () => ({
    mapInstance: null,
    isFetching: false,
    keyword: null,
    activeItem: null,
    items: []
  }),
  computed: {
    filteredItems () {
      if (!this.keyword) {
        return this.items
      }

      return this.items.filter(_item => {
        const foundByName = _item.fullname
          ?.toLowerCase()
          ?.includes(this.keyword.toLowerCase())

        const foundByPhone = _item.phone
          ?.toLowerCase()
          ?.includes(this.keyword.toLowerCase())

        const foundByAddress = _item.address
          ?.toLowerCase()
          ?.includes(this.keyword.toLowerCase())

        return foundByName || foundByPhone || foundByAddress
      })
    }
  },
  mounted () {
    Promise.all([
      this.getData(),
      this.initMap(),
    ]).then(() => {
      const firstItem = this.items.at(0)
      this.setActive(firstItem, initZoom)

      this.drawMarkers()
    })
  },
  methods: {
    async getData () {
      try {
        this.isFetching = true
        const { data } = await axios.get('https://online-sys.com/api.php?do=getmarkers')

        this.items = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isFetching = false
      }
    },
    async initMap () {
      try {
        const { Map } = await loader.importLibrary('maps')
        
        this.mapInstance = await new Map(document.querySelector('#map'), {
          center: { lat: 33.312911, lng: 44.355796 },
          zoom: initZoom,
          mapId: 'map1'
        })
      } catch (error) {
        alert('Failed to load map')
        console.log(error)
      }
    },
    setActive (item, zoom = 15) {
      this.activeItem = item
      // eslint-disable-next-line no-undef
      const newLoc = new google.maps.LatLng(item.latitude, item.longitude);
      this.mapInstance.setOptions({
        zoom,
        center: newLoc
      })
    },
    async drawMarkers () {
      const { InfoWindow } = await loader.importLibrary('maps')
      const { AdvancedMarkerElement } = await loader.importLibrary('marker')

      const infoWindow = new InfoWindow();

      const parser = new DOMParser();
      // A marker with a custom inline SVG.
      const pinSvgString =
        `<svg width="39" height="50" viewBox="0 0 39 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5 0C28.1357 0 35 6.82 35 15.4C35 26.84 19.5 44 19.5 44C19.5 44 4 26.84 4 15.4C4 6.82 10.8643 0 19.5 0ZM19.5 4.4C13.3 4.4 8.42857 9.24 8.42857 15.4C8.42857 17.6 8.42857 22 19.5 36.74C30.5714 22 30.5714 17.6 30.5714 15.4C30.5714 9.24 25.7 4.4 19.5 4.4ZM19.5 20.9L24.8143 24.2L23.4857 18.04L28.3571 13.86L21.9357 13.42L19.5 7.48L17.0643 13.2L10.6429 13.86L15.5143 18.04L13.9643 24.2L19.5 20.9Z" fill="#F79E1E"/>
        <ellipse cx="19.5" cy="47" rx="19.5" ry="3" fill="#9B9B9B" fill-opacity="0.09"/>
        </svg>`

      this.items.forEach(item => {
        try {
          const position = {
            lat: parseFloat(item?.latitude),
            lng: parseFloat(item?.longitude),
          }

          const pinSvg = parser.parseFromString(
            pinSvgString,
            "image/svg+xml",
          ).documentElement;
          
          const marker = new AdvancedMarkerElement({
            map: this.mapInstance,
            position,
            title: item.fullname,
            content: pinSvg
          });

          marker.addListener('click', () => {
            // const { target } = domEvent;

            infoWindow.close();
            infoWindow.setContent(`
              <div class="custom-tooltip">
                <h2 class="location-title">${marker.title}</h2>

                <div class="location-meta">
                  <div class="address">${item.address}</div>
                  <div class="phone">${item.phone}</div>
                </div>
              `);

            infoWindow.open(marker.map, marker);
          })
        } catch (error) {
          console.error(error)
        }
      })
    }
  }
}
</script>

<style>
.map-area {
  height: 65vh;
}

.map-area #map {
  width: 100%;
  height: 100%;
}

.menu-area {
  height: 100%;
  padding-bottom: 14px;
  height: 65vh;
  display: flex;
  flex-direction: column;
}

.menu-area .search-wrapper {
  margin-bottom: 8px;
  position: relative;
}

.menu-area .search-wrapper input {
  width: 100%;
  padding: 8px;
  padding-right: 32px;
  border: 1px solid rgb(218, 218, 218);
}

.menu-area .search-wrapper svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.menu-area .menu-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  padding-left: 4px;
}

/* width */
.menu-area .menu-wrapper::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.menu-area .menu-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.menu-area .menu-wrapper::-webkit-scrollbar-thumb {
  background: #d3d3d3;
}

/* Handle on hover */
.menu-area .menu-wrapper::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}


.menu-area .menu-wrapper .item {
  padding: 12px;
  display: flex;
  align-items: start;
  gap: 8px;
  border: 1px solid rgb(240, 240, 240);
}

.menu-area .menu-wrapper .item:hover {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(235, 235, 235, 0.171);
  border: 1px solid rgb(197, 197, 197);
}

.menu-area .menu-wrapper .item.active {
  border: 1px solid #F79E1E;
}

.gm-style button {
  outline: none;
  border: 1px solid red;
}

.gm-style .location-title {
  font-size: 14px;
}

.gm-style .location-meta {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 12px;
}

.gm-style .location-meta .address {
  font-weight: bold;
}

.gm-style .location-meta .phone {
  font-weight: bold;
}

.custom-tooltip {
  text-align: right;
  direction: rtl;
  font-family: "Almarai", sans-serif !important;
  padding: 20px;
}
</style>