<template>
  <section id="home" class="section welcome-area bg-inherit h-100vh overflow-hidden">
          <div class="shapes-container">
              <div class="bg-shape"></div>
          </div>
          <div class="container h-100">
              <div class="row align-items-center h-100">
                  <!-- Welcome Intro Start -->
                  <div class="col-12 col-md-7">
                      <div class="welcome-intro text-right">
                          <h1>
                            أفضل منصة عراقية لبيع البطاقات الالكترونية
                          </h1>
                          <p class="my-4">
                            جهاز و تطبيق اونلاين يتيح للوكلاء سهولة التعامل مع البطاقات الالكترونية وبطاقات تعبئة الرصيد من مختلف الشركات المحلية و الاجنبية بسهولة تامة وأنسب الاسعار
                          </p>
                      </div>
                  </div>
                  <div class="col-12 col-md-5">
                      <!-- Welcome Thumb -->
                      <div class="welcome-thumb" data-aos="fade-right" data-aos-delay="500" data-aos-duration="1000">
                          <img src="assets/img/home/online-app.png" alt="">
                      </div>
                  </div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
  
}
</script>

<style scoped>
/*BG SHAPE*/
.shapes-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(-1);
}

.shape {
  position: relative;
}

.bg-shape {
  position: absolute;
  height: 190%;
  width: 100%;
  display: block;
  border-radius: 120px;
  background: linear-gradient(-47deg, #D46034 0%, #D46034 100%);
  bottom: 0;
  left: 0;
  -webkit-transform: translate(35%,-28%) rotate(-35deg);
  transform: translate(35%,-28%) rotate(-35deg);
  z-index: 0;
}

@media (max-width: 991px) {
  .bg-shape {
      height: 130%;
  }
}
</style>