<template>
  <div dir="rtl" class="homepage-2">
    <ScrollupSection />
    <div class="main">
      <HeaderSection />
      <HeroSection/>
      <FeatureSection />
      <PartnerSection />
      <!-- <DiscoverSection /> -->
      <!-- <ServiceSection /> -->
      <WorkSection />
      <ScreenshotSection />
      <GallerySection />
      <!-- <ReviewSection /> -->
      <MapSection />
      <FaqSection />
      <DownloadSection />
      <!-- <NewsletterSection /> -->
      <!-- <ContactSection /> -->
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '../components/ScrollupSection/scrollUp'
import HeaderSection from '../components/HeaderSection/header'
import HeroSection from '../components/HeroSection/hero'
import FeatureSection from '../components/FeatureSection/feature'
// import DiscoverSection from '../components/DiscoverSection/discoverTwo'
// import ServiceSection from '../components/ServiceSection/serviceTwo'
import WorkSection from '../components/WorkSection/work'
import ScreenshotSection from '../components/ScreenshotSection/screenshot'
// import ReviewSection from '../components/ReviewSection/reviewTwo'
import PartnerSection from '../components/PartnerSection/partnerSection.vue'
import GallerySection from '../components/GallerySection/gallery'
import FaqSection from '../components/FaqSection/faq'
import DownloadSection from '../components/DownloadSection/download'
import MapSection from '../components/MapSection/map'
// import NewsletterSection from '../components/NewsletterSection/newsletter'
// import ContactSection from '../components/ContactSection/contactOne'
import FooterSection from '../components/FooterSection/footer'

export default {
  name: 'ThemeTwo',
  components: {
    ScrollupSection,
    HeaderSection,
    HeroSection,
    FeatureSection,
    // DiscoverSection,
    // ServiceSection,
    WorkSection,
    ScreenshotSection,
    GallerySection,
    // ReviewSection,
    PartnerSection,
    MapSection,
    FaqSection,
    DownloadSection,
    // NewsletterSection,
    // ContactSection,
    FooterSection
  }
}
</script>

<style>

</style>