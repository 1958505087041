export default [
  {
    title: 'ما هي شروط الاشتراك في منصة اونلاين ؟',
    description: 'يجب على الوكيل ان يكون لديه محل تجاري مسجل بشكل قانوني في جمهورية العراق، و يشترط توقيع عقد بين الطرفين و ايداع مبلغ افتتاحي للرصيد'
  },
  {
    title: 'ماهي انواع البطاقات المدعومة في التطبيق ؟',
    description: 'تتوفر لدينا مجموعة كبيرة جداً من انواع البطاقات الرقمية و بطاقات تعبئة الرصيد لمختلف الشركات العالمية و العراقية'
  },
  {
    title: 'هل يمكنني تحويل الارباح الى رصيد و استعماله في شراء بطاقات اخرى ؟',
    description: 'نعم، يمكنك تحويل رصيد الارباح الى رصيد يمكنك استعماله في شراء بطاقات اخرى من خلال التطبيق'
  },
  {
    title: 'هل يمكن متابعة تقارير الحساب من جهاز اخر ؟',
    description: 'نعم، يمكنك التواصل معنا لتفعيل خاصية الدخول من جهاز اخر و متابعة تقارير الحساب'
  }
]