<template>
  <header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand" href="index.html">
            <img class="navbar-brand-regular" src="assets/img/logo/logo-with-text.png" alt="brand-logo">
            <img class="navbar-brand-sticky" src="assets/img/logo/logo.png" alt="sticky brand-logo">
        </a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item" @click="closeNavbar()">
                        <a
                            class="nav-link"
                            href="#home"
                        >
                            الرئيسية
                        </a>
                    </li>
                    <li class="nav-item" @click="closeNavbar()">
                        <a class="nav-link scroll" href="#partners">
                            البطاقات
                        </a>
                    </li>
                    <li class="nav-item" @click="closeNavbar()">
                        <a class="nav-link scroll" href="#register">
                            طريقة الاشتراك
                        </a>
                    </li>
                    <li class="nav-item" @click="closeNavbar()">
                        <a class="nav-link scroll" href="#screenshots">
                            صور التطبيق
                        </a>
                    </li>
                    <li class="nav-item" @click="closeNavbar()">
                        <a class="nav-link scroll" href="#footer">
                            اتصل بنا
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
  </header>
</template>

<script>
export default {
    methods: {
        closeNavbar () {
            if (document.body.classList.contains('canvas-open')) {
                console.log('close navbar')
                document.querySelector('.navbar').classList.toggle('active')
                document.querySelector('.navbar-toggler-icon').classList.toggle('active')
                document.body.classList.toggle('canvas-open')
            }
        }
    }
}
</script>

<style scoped>

</style>