<template>
    <div class="blog">
        <ScrollupSection />
        <div class="main">
            <HeaderSection />
            <BlogBreadcrumb />
            <section id="blog" class="section blog-area ptb_100">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog res-margin">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_1.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">Anna Sword</a></li>
                                        <li><a href="#">Feb 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">How to grow up your business</a></h3>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog res-margin">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_2.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">Jassica William</a></li>
                                        <li><a href="#">Feb 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">Planing to manage your clients?</a></h3>
                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature.</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_3.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">John Doe</a></li>
                                        <li><a href="#">Mar 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">Make your successful business with sApp</a></h3>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog res-margin">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_4.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">Anna Sword</a></li>
                                        <li><a href="#">Feb 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">How to get more clients?</a></h3>
                                    <p>Need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators.</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog res-margin">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_5.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">Jassica William</a></li>
                                        <li><a href="#">Feb 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">Grow up your business successfully</a></h3>
                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical.</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_6.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">John Doe</a></li>
                                        <li><a href="#">Mar 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">Meet the business magnet of the year</a></h3>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog res-margin">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_7.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">Anna Sword</a></li>
                                        <li><a href="#">Feb 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">How to grow up your business</a></h3>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog res-margin">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_8.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">Jassica William</a></li>
                                        <li><a href="#">Feb 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">Planing to manage your clients?</a></h3>
                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin.</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <!-- Single Blog -->
                            <div class="single-blog">
                                <!-- Blog Thumb -->
                                <div class="blog-thumb">
                                    <a href="#"><img src="assets/img/blog_9.jpg" alt=""></a>
                                </div>
                                <!-- Blog Content -->
                                <div class="blog-content p-4">
                                    <!-- Meta Info -->
                                    <ul class="meta-info d-flex justify-content-between">
                                        <li>By <a href="#">John Doe</a></li>
                                        <li><a href="#">Mar 05, 2019</a></li>
                                    </ul>
                                    <!-- Blog Title -->
                                    <h3 class="blog-title my-3"><a href="#">Make your successful business with sApp</a></h3>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    <a href="#" class="blog-btn mt-3">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- Pagination -->
                            <ul class="pagination justify-content-center">
                                <li class="disabled px-1">
                                    <a href="#" aria-label="Previous">
                                        <i class="fas fa-arrow-left"></i>
                                    </a>
                                </li>
                                <li class="px-1"><a href="#">1</a></li>
                                <li class="active px-1"><a href="#">2</a></li>
                                <li class="px-1"><a href="#">3</a></li>
                                <li>
                                    <a href="#" aria-label="Next">
                                        <i class="fas fa-arrow-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </div>
    </div>
</template>

<script>
import ScrollupSection from '../../ScrollupSection/scrollUp'
import HeaderSection from '../../HeaderSection/header'
import BlogBreadcrumb from '../BlogBreadcrumb/breadcrumbTwo'
import FooterSection from '../../FooterSection/footer'

export default {
    name: 'BlogThreeColumn',
    components: {
        ScrollupSection,
        HeaderSection,
        BlogBreadcrumb,
        FooterSection
    }
}
</script>

<style>

</style>