<template>
    <div>
        <ScrollupSection />
        <div class="main">
            <HeaderSection />
            <InnerBreadcrumb />
            <ReviewSection />
            <FooterSection />
        </div>
    </div>
</template>

<script>
import ScrollupSection from '../../ScrollupSection/scrollUp'
import HeaderSection from '../../HeaderSection/header'
import InnerBreadcrumb from '../InnerBreadcrumb/breadcrumbTwo'
import ReviewSection from '../../ReviewSection/reviewFour'
import FooterSection from '../../FooterSection/footer'

export default {
    name: 'Reviews',
    components: {
        ScrollupSection,
        HeaderSection,
        InnerBreadcrumb,
        ReviewSection,
        FooterSection
    }
}
</script>

<style>

</style>